import React from 'react';
import PropTypes from 'prop-types';
import {VelocityComponent} from 'velocity-react';
import 'velocity-animate/velocity.ui';

const Animate = React.forwardRef(function Animate(props, ref) {
  const children = React.cloneElement(props.children, {
    style: {
      ...props.children.style,
      visibility: 'hidden'
    }
  });
  if (props.resetTransform){
    delete props.resetTransform
    return <VelocityComponent ref={ref} {...props} children={children} complete={elements => elements[0].style.transform = "unset"}/>
  }
  return <VelocityComponent ref={ref} {...props} children={children}/>
});

Animate.propTypes = {
  children: PropTypes.element.isRequired
};

Animate.defaultProps = {
  animation          : 'transition.expandIn',
  runOnMount         : true,
  targetQuerySelector: null,
  interruptBehavior  : 'stop',
  visibility         : 'visible',
  duration           : 200,//300,
  delay              : 200,//50,
  easing             : [0.4, 0.0, 0.2, 1],
  display            : null,
  setRef             : undefined
};

export default React.memo(Animate);
