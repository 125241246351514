import { socketManager } from "socket-io";

class userService {
  logout = () => {
    socketManager.send("auth-logout");
  };

  subscribeBalanceUpdate = (callback) => {
    socketManager.subscribe("balance-update", callback);
  };

  unsubscribeBalanceUpdate = () => {
    socketManager.unsubscribe("balance-update");
  };

  subscribeGroupUpdate = (callback) => {
    socketManager.subscribe("group-update", callback);
  };

  unsubscribeGroupUpdate = () => {
    socketManager.unsubscribe("group-update");
  };

  subscribeOrderStatusChanged = (callback) => {
    socketManager.subscribe("order-changed", callback);
  };

  unsubscribeStatusChanged = () => {
    socketManager.unsubscribe("order-changed");
  };

  getOrders = (pageSize, pageIndex, filter) => {
    return socketManager.send("get-user-orders", {
      pageSize,
      pageIndex,
      filter,
    });
  };

  getOrdersServiceNames = (type) => {
    return socketManager.send("get-user-orders-service-names", type);
  };

  getOrdersStats = () => {
    return socketManager.send("get-order-stats");
  };

  getTransactions = (pageSize, pageIndex, filter) => {
    return socketManager.send("get-transactions", {
      pageSize,
      pageIndex,
      filter,
    });
  };

  getOrderInfo = (orderId) => {
    return socketManager.send("get-order-info", orderId);
  };

  subscribeOrderUpdate = (callback) => {
    socketManager.subscribe("order-update", callback);
  };

  unsubscribeOrderUpdate = () => {
    socketManager.unsubscribe("order-update");
  };

  updateAccount = (data) => {
    return socketManager.send("update-account", data);
  };

  getProfile = () => {
    return socketManager.send("get-profile");
  };

  changePassword = (oldPass, newPass) => {
    return socketManager.send("change-pass", { oldPass, newPass });
  };

  getNotificationSettings = () => {
    return socketManager.send("get-notification-settings");
  };

  updateNotificationSettings = (data) => {
    return socketManager.send("update-notification-settings", data);
  };

  updateWelcomeMessageDisplayed = () => {
    return socketManager.send("update-welcome-displayed");
  };

  unsubscribeServicesNotifications = (data) => {
    return socketManager.send("unsubscribe-services-notifications", data);
  };

  getSignedRequestForUploadAvatar = () => {
    return socketManager.send("signed-request-upload-avatar");
  };

  getSignedRequestForUploadLogo = () => {
    return socketManager.send("adm-signed-request-upload-logo");
  };

  getSignedRequestForUploadLogoEmail = () => {
    return socketManager.send("adm-signed-request-upload-logo-email");
  };

  /*uploadOrderPicture = (file, signedRequest, url, callback) => {

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.onreadystatechange = () => {

      if(xhr.readyState === 4){
        if(xhr.status === 200){
          callback(true)
        }
        else{
          callback(false)
        }
      }
    };
    xhr.send(file);
  }*/

  uploadPicture = (file, params, signature, callback) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", params.apikey);
    formData.append("timestamp", params.timestamp);
    formData.append("public_id", params.public_id);
    formData.append("folder", params.folder);
    formData.append("signature", signature);
    //formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");

    fetch(params.url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        callback(JSON.parse(data));
      })
      .catch((e) => {
        callback(false);
      });
  };

  updateAvatar = (url) => {
    return socketManager.send("update-avatar", { url });
  };

  deleteAvatar = () => {
    return socketManager.send("delete-avatar");
  };

  getServicesSummary = (type) => {
    return socketManager.send("get-services-summary", type);
  };

  getServiceDetails = (serviceId) => {
    return socketManager.send("get-service-details", serviceId);
  };

  placeOrder = (data) => {
    return socketManager.send("place-order", data);
  };

  getFrequentServices = () => {
    return socketManager.send("get-frequent-services");
  };

  getEarnings = () => {
    return socketManager.send("get-earnings");
  };

  getNotifications = () => {
    return socketManager.send("get-notifications");
  };

  getIccids = () => {
    return socketManager.send("get-iccids");
  };

  cryptoCreateCharge = (amount) => {
    return socketManager.send("crypto-create-charge", { amount });
  };

  subscribeChargeUpdate = (callback) => {
    socketManager.subscribe("crypto-charge-update", callback);
  };

  unsubscribeChargeUpdate = () => {
    socketManager.unsubscribe("crypto-charge-update");
  };

  getPaymentExchangeRate = () => {
    return socketManager.send("get-payment-exchangerate");
  };

  getPaymentGateways = () => {
    return socketManager.send("get-payment-gateways");
  };

  // INVOICE

  createInvoice = (data) => {
    return socketManager.send("create-invoice", data);
  };

  getInvoices = (pageSize, pageIndex, filter) => {
    return socketManager.send("get-invoices", { pageSize, pageIndex, filter });
  };

  getInvoice = (id) => {
    return socketManager.send("get-invoice", id);
  };

  // BANNERS
  getLandingBanners = (isMobile) => {
    return socketManager.send("get-landing-banners", { mobile: isMobile });
  };
}

const instance = new userService();

export default instance;
