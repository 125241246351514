import { history } from "../../../history"
import jwtService from 'services/auth/jwt/jwtService';

export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const SET_LANGUAGE = '[USER] SET LANGUAGE';
export const SET_AVATAR = '[USER] SET_AVATAR';
export const SET_NAME = '[USER] SET_NAME';
export const SET_WELCOME_DISPLAYED = '[USER] SET_WELCOME_DISPLAYED'

/**
 * Set User Data
 */
export function setUserData(user)
{
  return {
    type   : SET_USER_DATA,
    payload: user
  }
}

/**
 * Set User Data
 */
export function setWelcomeDisplayed()
{
  return {
    type   : SET_WELCOME_DISPLAYED
  }
}


/**
 * Set Language
 */
export function setLanguage(language)
{
  return {
    type   : SET_LANGUAGE,
    payload: language
  }
}

/**
 * Set Avatar
 */
export function setAvatar(url)
{
  return {
    type   : SET_AVATAR,
    payload: url
  }
}

/**
 * Set Name
 */
export function setName(name)
{
  return {
    type   : SET_NAME,
    payload: name
  }
}

/**
 * Logout
 */
export function logoutUser()
{

  return (dispatch, getState) => {

    const user = getState().auth.user;

    if ( !user.role || user.role.length === 0 )// is guest
    {
      return null;
    }

    switch ( user.from )
    {
      case 'auth0':
      {
        //auth0Service.logout();
        break;
      }
      default:
      {
        jwtService.logout();
      }
    }

    history.push({
      pathname: '/login'
    });

    dispatch({
      type: USER_LOGGED_OUT
    })
  }
}
