import React from "react";
import * as Icon from "react-feather";
import authRoles from "./authRoles";

const navigationConfig = [
  {
    id: "login",
    title: "Login",
    type: "item",
    icon: <Icon.LogIn size={20} />,
    navLink: "/login",
    permissions: authRoles.onlyGuest,
  },
  /*{
    id: "register",
    title: "Register",
    type: "item",
    icon: <Icon.UserPlus size={20} />,
    navLink: "/register",
    permissions: authRoles.onlyGuest,
  },*/
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Monitor size={20} />,
    navLink: "/dashboard",
    permissions: authRoles.user,
  },
  {
    id: "placeorder",
    title: "Place Order",
    type: "collapse",
    icon: <Icon.CreditCard size={20} />,
    permissions: authRoles.user,
    children: [
      {
        id: "imeiservice",
        title: "IMEI Services",
        type: "item",
        icon: <Icon.Smartphone size={20} />,
        navLink: "/place-order/imei",
        permissions: authRoles.user,
      },
      {
        id: "remoteservice",
        title: "Remote Services",
        type: "item",
        icon: <Icon.Globe size={20} />,
        navLink: "/place-order/remote",
        permissions: authRoles.user,
      },
      {
        id: "serverservice",
        title: "Activations and Credits",
        type: "item",
        icon: <Icon.Server size={20} />,
        navLink: "/place-order/server",
        permissions: authRoles.user,
      },
    ],
  },
  {
    id: "orderhistory",
    title: "Order History",
    type: "collapse",
    icon: <Icon.Layers size={20} />,
    permissions: authRoles.user,
    children: [
      {
        id: "imeiorders",
        title: "IMEI Orders",
        type: "item",
        icon: <Icon.Smartphone size={20} />,
        navLink: "/orders/imei",
        permissions: authRoles.user,
      },
      {
        id: "remoteorders",
        title: "Remote Orders",
        type: "item",
        icon: <Icon.Globe size={20} />,
        navLink: "/orders/remote",
        permissions: authRoles.user,
      },
      {
        id: "serverorders",
        title: "Activations and Credits",
        type: "item",
        icon: <Icon.Server size={20} />,
        navLink: "/orders/server",
        permissions: authRoles.user,
      },
    ],
  },
  {
    id: "contactus",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Mail size={20} />,
    navLink: "/contact",
    permissions: authRoles.all,
  },
  {
    id: "help",
    title: "Help",
    type: "item",
    icon: <Icon.HelpCircle size={20} />,
    navLink: "/help",
    permissions: authRoles.user,
  },
  {
    id: "admin",
    title: "Admin",
    type: "collapse",
    icon: <Icon.Shield size={20} />,
    permissions: authRoles.manager,
    children: [
      {
        id: "adminDashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.PieChart size={20} />,
        navLink: "/admin/dashboard",
        permissions: authRoles.admin,
      },
      {
        id: "users",
        title: "Users",
        type: "item",
        icon: <Icon.Users size={20} />,
        navLink: "/admin/users",
        permissions: authRoles.manager,
      },
      {
        id: "orders",
        title: "Orders",
        type: "item",
        icon: <Icon.Server size={20} />,
        navLink: "/admin/orders/all",
        permissions: authRoles.manager,
      },
      {
        id: "services",
        title: "Services",
        type: "item",
        icon: <Icon.List size={20} />,
        navLink: "/admin/services",
        permissions: authRoles.admin,
      },
      {
        id: "database",
        title: "Databases",
        type: "item",
        icon: <Icon.Database size={20} />,
        navLink: "/admin/databases",
        permissions: authRoles.admin,
      },
      {
        id: "transactions",
        title: "Transactions",
        type: "item",
        icon: <Icon.Layers size={20} />,
        navLink: "/admin/transactions",
        permissions: authRoles.admin,
      },
      {
        id: "apis",
        title: "Apis",
        type: "item",
        icon: <Icon.Link size={20} />,
        navLink: "/admin/apis",
        permissions: authRoles.admin,
      },
      {
        id: "notifications",
        title: "Notifications",
        type: "item",
        icon: <Icon.Bell size={20} />,
        navLink: "/admin/notifications",
        permissions: authRoles.admin,
      },
      {
        id: "invoices",
        title: "Invoices",
        type: "item",
        icon: <Icon.FileText size={20} />,
        navLink: "/admin/invoices",
        permissions: authRoles.admin,
      },
      {
        id: "settings",
        title: "Settings",
        type: "collapse",
        icon: <Icon.Settings size={20} />,
        permissions: authRoles.admin,
        children: [
          {
            id: "general",
            title: "General",
            type: "item",
            icon: <Icon.Globe size={20} />,
            navLink: "/admin/settings/general",
            permissions: authRoles.admin,
          },
          {
            id: "landing",
            title: "Home page",
            type: "item",
            icon: <Icon.Grid size={20} />,
            navLink: "/admin/settings/landing",
            permissions: authRoles.admin,
          },
          {
            id: "emails",
            title: "Emails",
            type: "item",
            icon: <Icon.Mail size={20} />,
            navLink: "/admin/settings/emails",
            permissions: authRoles.admin,
          },
          {
            id: "contact-info",
            title: "Contact info",
            type: "item",
            icon: <Icon.AtSign size={20} />,
            navLink: "/admin/settings/contact-info",
            permissions: authRoles.admin,
          },
          {
            id: "payment-gateway",
            title: "Payment Gateways",
            type: "item",
            icon: <Icon.CreditCard size={20} />,
            navLink: "/admin/settings/payment-gateways",
            permissions: authRoles.admin,
          },
          {
            id: "telegram",
            title: "Telegram",
            type: "item",
            icon: <Icon.Send size={20} />,
            navLink: "/admin/settings/telegram",
            permissions: authRoles.admin,
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
