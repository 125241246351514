import { combineReducers } from "redux"
import { login } from "./loginReducer"
import { register } from "./registerReducers"
import { user } from "./userReducer"
import { errors } from "./errorReducer"

const authReducers = combineReducers({
  login,
  register,
  user,
  errors
})

export default authReducers
