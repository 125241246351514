import React from 'react'
import { toast } from "react-toastify"
import { Info } from "react-feather"

export const error = (msg, position = toast.POSITION.TOP_CENTER) => {
  toast.error(<div className="d-flex align-items-center"><Info size={18} className="flex-shrink-0"/> <span>{msg}</span></div>, {
    position
  })
}

export const primary = (msg,  position = toast.POSITION.TOP_CENTER) => {
  toast.success(<div className="d-flex align-items-center"><Info size={18}/> <span>{msg}</span></div>, {
    position,
    className: 'bg-primary'
  })
}

export const warning = (msg,  position = toast.POSITION.TOP_CENTER) => {
  toast.success(<div className="d-flex align-items-center"><Info size={18}/> <span>{msg}</span></div>, {
    position,
    className: 'bg-warning'
  })
}

export const success = (msg,  position = toast.POSITION.TOP_CENTER) => {
  toast.success(<div className="d-flex align-items-center"><Info size={18}/> <span>{msg}</span></div>, {
    position,
    className: 'bg-success'
  })
}


