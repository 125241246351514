import React, { useEffect, useState } from 'react'
import Spinner from "components/@vuexy/spinner/Fallback-spinner"
import { connect, useDispatch } from 'react-redux'
//import * as userActions from 'app/auth/store/actions';
//import * as Actions from 'app/store/actions';
/*import auth0Service from 'app/services/auth0Service';
*/
import {setUserData, logoutUser} from 'redux/actions/auth/userActions'
import jwtService from 'services/auth/jwt/jwtService';
import userService from 'services/app/userService'

import * as ToastMessage from 'utility/ToastMessage'

const UserSession =  (props) => {

  const [waitAuthCheck, setWaitAuthCheck] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {

    if (waitAuthCheck) {
      Promise.all([
        // Comment the lines which you do not use
        //this.firebaseCheck(),
        //this.auth0Check(),
        jwtCheck()
      ]).then(() => {
        setWaitAuthCheck(false)
      })
    }
  })

  useEffect(() => {

    userService.subscribeBalanceUpdate((data) => {
      dispatch(setUserData(data))
    })

    userService.subscribeGroupUpdate((data) => {
      dispatch(setUserData(data))
    })

    return () => {
      userService.unsubscribeBalanceUpdate()
      userService.unsubscribeGroupUpdate()
    }

  },[])

  const jwtCheck = () => new Promise(resolve => {

    jwtService.on('onAutoLogin', () => {

      //this.props.showMessage({message: 'Logging in'});

      /**
       * Sign in and retrieve user data from Api
       */
      jwtService.signInWithToken()
        .then(user => {

          dispatch(setUserData(user));

          resolve();

          //this.props.showMessage({message: 'Logged in successfully'});
        })
        .catch(error => {

          //this.props.showMessage({message: error});

          resolve();
        })
    });

    jwtService.on('onAutoLogout', (message) => {

      if ( message )
      {
        //this.props.showMessage({message});

        //crack porque como este componente esta por encima de la internacionalizacion para que no se hande renderizando cuando
        // se cambie de lenguage entonces no ve los datos de los lenguages
        const language = localStorage.getItem('language') || 'es'
        ToastMessage.error(language === 'es' ? 'Sesión expirada. Por favor ingrese de nuevo' : 'Session expired. Please login')
      }

      dispatch(logoutUser());

      resolve();
    });

    jwtService.on('onNoAccessToken', () => {

      resolve();
    });

    jwtService.init();

    return Promise.resolve();
  })

  return waitAuthCheck ? <Spinner/> : <React.Fragment children={props.children}/>;

}


export default UserSession;
