import React, {useCallback, useEffect, useRef, useState} from "react"
import VerticalLayout from "../../layouts/VerticalLayout"
import FullLayout from "../../layouts/FullpageLayout"
import HorizontalLayout from "../../layouts/HorizontalLayout"
import themeConfig from "../../configs/themeConfig"

import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import "assets/scss/plugins/extensions/toastr.scss"
import {connect, useDispatch, useSelector} from 'react-redux'
import {changeMenuLayout} from "../../redux/actions/customizer";
import useLocalStorage from "../hooks/useLocalStorage";
//import { changeMenuColor, changeMode } from '../../redux/actions/customizer'
import {isMobile} from "react-device-detect";

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  horizontal: HorizontalLayout,
}

const ContextLayout = React.createContext()

const AppLayout = (props) => {

  const layout = useSelector(({customizer}) => customizer.layout)
  const activeLayout = isMobile ? "vertical" : layout

  const activeLayoutRef = useRef(activeLayout)
  const lastLayoutRef = useRef(null)

  const width = useRef(window.innerWidth)
  const height = useRef(window.innerHeight)

  const [direction, setDirection] = useState(themeConfig.direction)

  const dispatch = useDispatch()

  const updateWindowSize = () => {
    width.current = window.innerWidth
    height.current = window.innerHeight
  }

  const setActiveLayout = useCallback((mode) => {

    if (mode !== activeLayoutRef.current){
      dispatch(changeMenuLayout(mode))
    }

  },[activeLayout])

  const handleWindowResize = () => {
    updateWindowSize()

    if (activeLayoutRef.current === "horizontal") {

      if (width.current <= 1199){
        lastLayoutRef.current = "horizontal"
        setActiveLayout("vertical")
      }
      else{
        setActiveLayout("horizontal")
      }

    }
    else { //if vertical layout active

      if (width.current > 1199 && lastLayoutRef.current === "horizontal"){
        lastLayoutRef.current = "vertical"
        setActiveLayout("horizontal")
      }

    }

    /*if ((layoutSetting === "horizontal" || themeConfig.layout === "horizontal") && width.current > 1199) {
      setActiveLayout("horizontal")
    }*/
  }

  const handleDirUpdate = () => {
    let dir = direction
    if (dir === "rtl")
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
    else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
  }

  useEffect(() => {

    handleWindowResize()
    handleDirUpdate()

    if (window !== undefined) {
      window.addEventListener("resize", handleWindowResize)
    }

    return () => {
      if (window !== undefined) {
        window.removeEventListener("resize", handleWindowResize)
      }
    }
    //toast.configure({className: `theme-${this.props.app.menuTheme}`})

  },[window])

  useEffect(() => {

    activeLayoutRef.current = activeLayout

  }, [activeLayout])

  const state = {
    activeLayout,
    width: width.current,
    height: height.current,
    direction
  }

  const { children } = props

  return (
    <ContextLayout.Provider
      value={{
        state,
        fullLayout: layouts["full"],
        VerticalLayout: layouts["vertical"],
        horizontalLayout: layouts["horizontal"],
        switchLayout: layout => {
          setActiveLayout(layout)
        },
        switchDir: dir => {
          setDirection(dir)
        }
      }}
    >
      <React.Fragment>
        {children}
        <ToastContainer autoClose={8000} className={`theme-${props.app.menuTheme}`}/>
      </React.Fragment>
    </ContextLayout.Provider>

  )

}

const mapStateToProps = state => {
  return {
    app: state.customizer
  }
}

const Layout = connect(mapStateToProps, {})(AppLayout)

export { Layout, ContextLayout }
