/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  manager: ["admin", "manager"],
  reseller: ["admin", "manager", "reseller"],
  supplier: ["supplier"],
  user: ["admin", "manager", "reseller", "user"],
  loggedIn: ["admin", "manager", "reseller", "user", "supplier"],
  onlyGuest: [],
  all: undefined,
};

export default authRoles;
