import React, { useState } from 'react'
import { withRouter } from 'react-router'
//import CountUp from 'react-countup';
import Animate from 'components/others/Animate/Animate'
import CountUp from 'react-countup'

const NavbarUserStatus = (props) => {

  const [prevBalance] = useState(props.user.balance)
  const balance = Math.round((props.user.balance + Number.EPSILON) * 1000) / 1000

  const countDecimals = function (value) {
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  }

  return (
    <span className="user-status">
        {prevBalance !== props.user.balance ?
          <React.Fragment>$ <CountUp start={prevBalance} end={props.user.balance} decimals={countDecimals(props.user.balance)} preserveValue={true}/></React.Fragment>
          :
          <Animate animation="transition.fadeIn" delay={500}><span>$ {balance}</span></Animate>
        }
    </span>
  )
}

export default withRouter(NavbarUserStatus)
