import React, { useEffect, useState } from 'react'
import { IntlProvider } from "react-intl"
import { useDispatch, useSelector } from 'react-redux'
import { socketManager } from '../../socket-io'
import { setLanguage } from 'redux/actions/auth/userActions'

const Context = React.createContext()

const IntlProviderWrapper = (props) => {

  const user = useSelector(({auth}) => auth.user)
  const activeLanguage = user.loggedIn ? user.settings.language : (localStorage.getItem('language') || "en")

  const [locale, setLocale] = useState(activeLanguage)
  const [messages, setMessages] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {

    loadCatalog(activeLanguage)

  },[activeLanguage])

  const loadCatalog = async (language) => {
    let messages = await import(`../../assets/data/locales/${language}.json`)

    setLocale(language)
    setMessages(messages)
  }

  const handleSwitchLanguage = language => {
    loadCatalog(language)
    localStorage.setItem("language", language)

    dispatch(setLanguage(language))
    //save to db
    if (user.loggedIn){
      socketManager.send('set-language', language)
    }
  }

  const { children } = props

  return (
    <Context.Provider
      value={{
        state: {locale, messages},
        switchLanguage: handleSwitchLanguage
      }}
    >
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )

}

export { IntlProviderWrapper, Context as IntlContext }
