import * as Actions from '../../actions/auth/userActions'
import {SET_WELCOME_DISPLAYED} from "../../actions/auth/userActions";

const initialState = {
  role: [],//guest
  profile: {
  },
  settings:{
  },
  loggedIn: false,
  group: ""
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return { ...state, ...action.payload, loggedIn: true }
    }
    case Actions.SET_LANGUAGE: {
      return { ...state, settings: { ...state.settings, language: action.payload} }
    }
    case Actions.SET_WELCOME_DISPLAYED: {
      return {...state, notifications: {...state.notifications, welcomeDisplayed: true}}
    }
    case Actions.SET_AVATAR: {
      return { ...state, profile: { ...state.profile, avatar: action.payload} }
    }
    case Actions.SET_NAME: {
      return { ...state, profile: { ...state.profile, name: action.payload} }
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState
    }


    default: {
      return state
    }
  }
}
