import { socketManager } from "socket-io";

class navbarService {
  subscribeNewNotification = (callback) => {
    socketManager.subscribe("adm-new-admnotification", callback);
  };

  unsubscribeNewNotification = () => {
    socketManager.unsubscribe("adm-new-admnotification");
  };

  subscribeSuccessOrder = (callback) => {
    socketManager.subscribe("order-success", callback);
  };

  unsubscribeSuccessOrder = () => {
    socketManager.unsubscribe("order-success");
  };

  subscribeRejectedOrder = (callback) => {
    socketManager.subscribe("order-rejected", callback);
  };

  unsubscribeRejectedOrder = () => {
    socketManager.unsubscribe("order-rejected");
  };

  setSeenNotifications = () => {
    return socketManager.send("adm-setseen-admnotifications");
  };

  getAdminNotifications = () => {
    return socketManager.send("adm-get-admnotifications");
  };

  deleteAllNotifications = () => {
    return socketManager.send("adm-deleteall-admnotifications");
  };
}

const instance = new navbarService();

export default instance;
