import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
import { socketManager, SocketIOContext } from './socket-io'
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import UserSession from './components/others/Authorization/UserSession'
const LazyApp = lazy(() => import("./App"))


// configureDatabase()
ReactDOM.render(
  <SocketIOContext.Provider value={socketManager}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <UserSession>
            <IntlProviderWrapper>
              <LazyApp />
            </IntlProviderWrapper>
          </UserSession>
        </Layout>
      </Suspense>
    </Provider>
  </SocketIOContext.Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

//prevent fallback warning message on development for the react intl
if (process.env.NODE_ENV !== 'production') {
  const originalConsoleError = console.error
  if (console.error === originalConsoleError) {
    console.error = (...args) => {
      if (args[0].indexOf('[React Intl] Missing message:') === 0 || args[0].indexOf('[React Intl] Cannot format message:') === 0) {
        return
      }
      originalConsoleError.call(console, ...args)
    }
  }
}
