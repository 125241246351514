const initialState = {};
export const register = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNUP_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "REGISTER_ACTIVATED": {
      return initialState
    }
    case "SIGNUP_WITH_JWT":
      return {
        ...state,
        user: action.payload,
        activated: false
      }
    default: {
      return state
    }
  }
}
