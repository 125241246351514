import { socketManager } from "socket-io";
import jwtDecode from "jwt-decode";
import Utils from "utility/Utils";
import userService from "../../app/userService";

class jwtService extends Utils.EventEmitter {
  sessionTimeout = null;

  init() {
    //this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    /*axios.interceptors.response.use(response => {
      return response;
    }, err => {
      return new Promise((resolve, reject) => {
        if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest )
        {
          // if you ever get an unauthorized response, logout the user
          this.emit('onAutoLogout', 'Invalid access_token');
          this.setSession(null);
        }
        throw err;
      });
    });*/
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.sessionLogout();
      //this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    /*return new Promise((resolve, reject) => {
      axios.post('/api/auth/register', data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        if (error.response) {
          reject(error.response.data);
        }
      });
    });*/
  };

  activateUser = (data) => {
    /*return new Promise((resolve, reject) => {
      axios.get(`/api/auth/activate/${data.token}`)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        if (error.response) {
          reject(error.response.data);
        }
      });
    });*/
  };

  signInWithEmailAndPassword = (user) => {
    return new Promise((resolve, reject) => {
      socketManager.send("login", user).then((res) => {
        if (res.status === 200) {
          this.setSession(res.access_token).then(() => {
            resolve(res.user);
          });

          this.scheduleLogout(res.access_token);
        } else if (res.status === 400) {
          reject(res);
        }
      });
    });
  };

  signInWithToken = (token) => {
    return new Promise((resolve, reject) => {
      if (token) {
        localStorage.setItem("_ai", token);
      }
      const access_token = token ? token : this.getAccessToken();

      socketManager.send("loginWithToken", { access_token }).then((res) => {
        if (res.status === 200) {
          this.setSession(access_token).then(() => {
            resolve(res.user);
          });

          this.scheduleLogout(access_token);
        } else if (res.status === 400) {
          this.logout();
          reject("Failed to login with token.");
        }
      });
    });

    /*return new Promise((resolve, reject) => {
      axios.post('/api/auth/access-token', {
        access_token: this.getAccessToken()
      })
        .then(response => {
          if ( response.data.user )
          {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          }
          else
          {
            this.logout();
            reject('Failed to login with token.');
          }
        })
        .catch(error => {
          this.logout();
          reject('Failed to login with token.');
        });
    });*/
  };

  updateUserData = (user) => {
    /*return axios.post('/api/auth/user/update', {
      user: user
    });*/
  };

  setSession = (access_token) => {
    return new Promise((resolve) => {
      if (access_token) {
        localStorage.setItem("_ai", access_token);

        resolve();
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      } else {
        localStorage.removeItem("_ai");
        socketManager.unauthorize();

        resolve();
        //delete axios.defaults.headers.common['Authorization'];
      }
    });
  };

  logout = () => {
    userService.logout();

    this.setSession(null);
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
    }
  };

  sessionLogout = () => {
    userService.logout();

    this.setSession(null);
    this.emit("onAutoLogout", null);
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
    }
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);

    const currentTime = Date.now() / 1000;
    return decoded.exp >= currentTime;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("_ai");
  };

  getAccessTokenValidated = () => {
    let access_token = this.getAccessToken();

    if (!access_token) return null;

    if (this.isAuthTokenValid(access_token)) {
      return access_token;
    }

    return null;
  };

  scheduleLogout = (access_token) => {
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp >= currentTime) {
      let timeToLogout = (decoded.exp - currentTime) * 1000;

      if (this.sessionTimeout !== null) {
        clearTimeout(this.sessionTimeout);
      }

      this.sessionTimeout = setTimeout(() => {
        this.emit("onAutoLogout", "Session expired. Please login");
      }, timeToLogout);
    }
  };

  requestResetPasswordLink = (data) => {
    /*return new Promise((resolve, reject) => {
      axios.post('/api/auth/forgot', data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        if (error.response) {
          reject(error.response.data);
        }
      });
    });*/
  };

  validateResetLink = (token) => {
    /*return new Promise((resolve, reject) => {
      axios.get(`/api/auth/forgot/${token}`)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        if (error.response) {
          reject(error.response.data);
        }
      });
    });*/
  };

  passwordReset = (data) => {
    /*return new Promise((resolve, reject) => {
      axios.post('/api/auth/forgot/reset', data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
        if (error.response) {
          reject(error.response.data);
        }
      });
    });*/
  };
}

const instance = new jwtService();

export default instance;
