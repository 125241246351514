import {APP_SET_LOADING, APP_SET_SETTINGS} from '../../actions/app'

const initialState = {
  loading: false,
  web: {
    url: '',
    name: '',
    logo: '',
    logoEmail: '',
    email: '',
    phone: '',
    facebook: '',
    telegram: ''
  }
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_LOADING:
      return { ...state, loading: action.value }
    case APP_SET_SETTINGS:
      return { ...state, web: action.value }
    default:
      return state
  }
}

export default appReducer
