import { SET_ERRORS } from '../../actions/auth/errorActions'

export const errors = (state = {}, action) => {
  switch (action.type) {
    case SET_ERRORS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
