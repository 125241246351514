import React, {useEffect, useState} from "react"
import { CustomInput, FormGroup } from "reactstrap"
import { Settings, X } from "react-feather"
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { ContextLayout } from "utility/context/Layout"
import "assets/scss/components/customizer.scss"
import { FormattedMessage } from 'react-intl'
import {useDispatch, useSelector} from "react-redux";
import {changeMenuLayout} from "redux/actions/customizer";
import {isMobile} from "react-device-detect";

const Customizer = (props) => {

  const [menuTheme, setMenuTheme] = useState(props.menuTheme)
  const [activeMode, setActiveMode] = useState(props.activeMode)

  const layout = useSelector(({customizer}) => customizer.layout)

  const dispatch = useDispatch()

  useEffect(() => {

    setMenuTheme(props.menuTheme)
    setActiveMode(props.activeMode)

  },[props.menuTheme, props.activeMode])

  const handleDarkModeChange = (e) => {
    props.changeMode(e.target.checked ? "dark" : "light")
  }

  const setMenuLayout = (mode) => {
    dispatch(changeMenuLayout(mode))
  }

  return (
    <ContextLayout.Consumer>
      {context => {
        return (
          <div
            className={classnames("customizer d-block", {
              open: props.customizerState === true
            })}
          >
            <div
              className="customizer-toggle"
              onClick={() =>
                props.handleCustomizer(!props.customizerState)
              }
            >
              <Settings className="open-icon" size={15} />
            </div>
            <div className="header d-flex justify-content-between px-2 pt-2">
              <div className="title">
                <h4 className="text-uppercase mb-0"><FormattedMessage id="Theme Customizer"/></h4>
                <small><FormattedMessage id="Customize & Preview in Real Time"/></small>
              </div>
              <div
                className="close-icon cursor-pointer"
                onClick={() => props.handleCustomizer(false)}
              >
                <X size={24} />
              </div>
            </div>
            <hr />
            <PerfectScrollbar
              options={{
                wheelPropagation: false
              }}
              className="customizer-content p-2"
            >
              <div className="menu-theme-colors">
                <h5><FormattedMessage id="Theme Colors"/></h5>
                <ul className="list-inline unstyled-list">
                  <li
                    className={classnames("color-box bg-primary", {
                      selected: menuTheme === "primary" ||  !["primary", "danger", "info", "warning", "dark", "success"].includes(menuTheme)
                    })}
                    onClick={() => props.changeMenuTheme("primary")}
                  />
                  <li
                    className={classnames("color-box bg-success", {
                      selected: menuTheme === "success"
                    })}
                    onClick={() => props.changeMenuTheme("success")}
                  />
                  <li
                    className={classnames("color-box bg-danger", {
                      selected: menuTheme === "danger"
                    })}
                    onClick={() => props.changeMenuTheme("danger")}
                  />
                  <li
                    className={classnames("color-box bg-info", {
                      selected: menuTheme === "info"
                    })}
                    onClick={() => props.changeMenuTheme("info")}
                  />
                  <li
                    className={classnames("color-box bg-warning", {
                      selected: menuTheme === "warning"
                    })}
                    onClick={() => props.changeMenuTheme("warning")}
                  />
                  <li
                    className={classnames("color-box bg-dark", {
                      selected: menuTheme === "dark"
                    })}
                    onClick={() => props.changeMenuTheme("dark")}
                  />
                </ul>
              </div>
              <hr />
              <div className="theme-mode">
                <h5 className="mt-1"><FormattedMessage id="Dark Mode"/></h5>
                <div className="d-inline-block mr-1">
                  <CustomInput
                    type="switch"
                    id="darkMode"
                    name="customSwitch"
                    checked={activeMode === "dark"}
                    onChange={handleDarkModeChange}
                    inline
                  />
                </div>
              </div>
              <hr />
              {!isMobile &&
                <div>
                  <h5 className="mt-1"><FormattedMessage id="Menu Layout"/></h5>
                  <div className="d-inline-block mr-1">
                    <FormGroup>
                      <div className='d-flex align-items-center'>
                        <CustomInput
                          type='radio'
                          id='horizontal-layout'
                          label='Horizontal'
                          checked={layout === 'horizontal'}
                          onChange={() => setMenuLayout('horizontal')}
                          className='mr-1'
                        />
                        <CustomInput
                          type='radio'
                          id='vertical-layout'
                          label='Vertical'
                          checked={layout === 'vertical'}
                          onChange={() => setMenuLayout('vertical')}
                        />
                      </div>
                    </FormGroup>
                  </div>
                </div>
              }
            </PerfectScrollbar>
          </div>
        )
      }}
    </ContextLayout.Consumer>
  )

}

export default Customizer
