import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import app from "./app"
//import contacts from "./contacts/"

const rootReducer = combineReducers({
  app: app,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
})

export default rootReducer
