import React, { useEffect, useState } from "react";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Dropdown,
  UncontrolledTooltip,
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Icon from "react-feather";
import { history } from "../../../history";
import { IntlContext } from "../../../utility/context/Internationalization";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { logoutUser } from "redux/actions/auth/userActions";
import authRoles from "../../../configs/authRoles";
import Utils from "utility/Utils";
/*import {
  changeFooterType,
  changeMenuColor, changeMode,
  changeNavbarColor,
  changeNavbarType, hideScrollToTop
} from '../../../redux/actions/customizer'
import { setIsFullScreen } from '../../../redux/actions/navbar/Index'*/
import fscreen from "fscreen";
import defaultAvatar from "assets/img/portrait/small/avatar.png";
import NavbarUserStatus from "./NavbarUserStatus";
import { injectIntl } from "react-intl";
import navbarService from "services/app/navbarService";
import userService from "services/app/userService";
import * as ToastMessage from "utility/ToastMessage";
import SweetAlert from "react-bootstrap-sweetalert";

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const dispatch = useDispatch();
  const userRole = useSelector(({ auth }) => auth.user.role);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const userAllow = Utils.hasPermission(authRoles.user, userRole);
  const loggedInAllow = Utils.hasPermission(authRoles.loggedIn, userRole);

  return (
    <DropdownMenu right>
      <React.Fragment>
        {userAllow && (
          <DropdownItem
            tag="a"
            href="#"
            onClick={(e) => handleNavigation(e, "/add-balance")}
          >
            <Icon.Mail size={14} className="mr-50" />
            <span className="align-middle">
              <FormattedMessage id="Add Balance" />
            </span>
          </DropdownItem>
        )}
        {loggedInAllow && (
          <>
            <DropdownItem
              tag="a"
              href="#"
              onClick={(e) => handleNavigation(e, "/activity")}
            >
              <Icon.Layers size={14} className="mr-50" />
              <span className="align-middle">
                <FormattedMessage id="Activity" />
              </span>
            </DropdownItem>
            <DropdownItem
              tag="a"
              href="#"
              onClick={(e) => handleNavigation(e, "/invoices")}
            >
              <Icon.FileText size={14} className="mr-50" />
              <span className="align-middle">
                <FormattedMessage id="Invoices" />
              </span>
            </DropdownItem>
          </>
        )}
        {userAllow && (
          <DropdownItem
            tag="a"
            href="#"
            onClick={(e) => handleNavigation(e, "/account-settings")}
          >
            <Icon.Settings size={14} className="mr-50" />
            <span className="align-middle">
              <FormattedMessage id="Settings" />
            </span>
          </DropdownItem>
        )}
        <DropdownItem divider />
      </React.Fragment>

      <DropdownItem tag="a" onClick={handleLogout}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="Logout" />
        </span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = (props) => {
  const [langDropdown, setLangDropdown] = useState(false);
  const [notiDropdown, setNotiDropdown] = useState(false);
  const [userDropdown, setUserDropdown] = useState(false);
  const [navbarSearch, setNavbarSearch] = useState(false);
  //const [suggestions, setSuggestions] = useState([])
  const [isFullScreen, setIsFullScreen] = useState(
    fscreen.fullscreenElement !== null
  );

  const [notifications, setNotifications] = useState([]);
  const [unseenNotifications, setUnseenNotifications] = useState(0);

  const [groupChangeAlert, setGroupChangeAlert] = useState(false);
  const intl = useIntl();

  const { user } = props;

  useEffect(() => {
    if (user) {
      userService.subscribeGroupUpdate((data) => {
        setGroupChangeAlert(true);
      });

      navbarService.subscribeNewNotification((notification) => {
        switch (notification.priority) {
          case "danger":
            ToastMessage.error(
              notification.title[props.intl.locale],
              "top-right"
            );
            break;
          case "warning":
            ToastMessage.warning(
              notification.title[props.intl.locale],
              "top-right"
            );
            break;
          default:
            ToastMessage.primary(
              notification.title[props.intl.locale],
              "top-right"
            );
            break;
        }

        setNotifications((prev) => {
          const newNotifications = prev.slice();
          newNotifications.unshift(notification);
          return newNotifications;
        });

        setUnseenNotifications((prev) => prev + 1);
      });

      navbarService.subscribeSuccessOrder(() => {
        ToastMessage.success(
          props.intl.formatMessage({ id: "Order replied" }),
          "top-right"
        );
      });

      navbarService.subscribeRejectedOrder(() => {
        ToastMessage.error(
          props.intl.formatMessage({ id: "Order rejected" }),
          "top-right"
        );
      });

      if (user.role === "admin") {
        navbarService.getAdminNotifications().then((res) => {
          if (res.status === 200) {
            setNotifications(res.data);

            let unseen = 0;

            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].unseen) {
                unseen++;
              }
            }

            setUnseenNotifications(unseen);
          }
        });
      }

      return () => {
        userService.unsubscribeGroupUpdate();
        navbarService.unsubscribeNewNotification();

        navbarService.unsubscribeSuccessOrder();
        navbarService.unsubscribeRejectedOrder();
      };
    }
  }, [user]);

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener("fullscreenchange", fullScreenChanged, false);
    }

    return () => {
      if (fscreen.fullscreenEnabled) {
        fscreen.removeEventListener(
          "fullscreenchange",
          fullScreenChanged,
          false
        );
      }
    };
  }, []);

  const fullScreenChanged = () => {
    setIsFullScreen(fscreen.fullscreenElement !== null);
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      fscreen.requestFullscreen(document.documentElement);
    } else {
      fscreen.exitFullscreen();
    }
  };

  const handleNavbarSearch = () => {
    setNavbarSearch(!navbarSearch);
  };

  const handleLangDropdown = () => setLangDropdown(!langDropdown);

  const handleNotiDropdown = () => {
    setNotiDropdown(!notiDropdown);

    if (!notiDropdown) {
      if (unseenNotifications > 0) {
        navbarService.setSeenNotifications();
      }
    } else {
      setUnseenNotifications(0);
    }
  };

  const openLangDropdown = (id) => setLangDropdown(true);

  const closeLangDropdown = (id) => setLangDropdown(false);

  const handleUserDropdown = () => setUserDropdown(!userDropdown);

  const openUserDropdown = (id) => setUserDropdown(true);

  const closeUserDropdown = (id) => setUserDropdown(false);

  const deleteAllNotifications = () => {
    navbarService.deleteAllNotifications().then((res) => {
      if (res.status === 200) {
        ToastMessage.primary(
          props.intl.formatMessage({ id: "Notifications deleted" })
        );
        setNotifications([]);
        setUnseenNotifications(0);
      } else {
        ToastMessage.error(res.error);
      }
    });
  };

  const handleNotificationClick = (service, invoice) => {
    if (service) {
      history.push(`/admin/services/edit/${service}`);
      handleNotiDropdown();
    } else if (invoice) {
      history.push(`/admin/invoices/${invoice}`);
      handleNotiDropdown();
    }
  };

  let group = "";

  if (user.loggedIn) {
    group =
      user.group === "retail"
        ? null
        : user.group === "vip"
        ? "VIP"
        : user.group === "usd"
        ? "USD"
        : props.intl.formatMessage({
            id: user.group.charAt(0).toUpperCase() + user.group.slice(1),
          });
  }

  return (
    <React.Fragment>
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <IntlContext.Consumer>
          {(context) => {
            let langArr = {
              en: "English",
              es: "Spanish",
            };
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={langDropdown}
                toggle={handleLangDropdown}
                onMouseEnter={() => {
                  openLangDropdown();
                }}
                onMouseLeave={() => {
                  closeLangDropdown();
                }}
                data-tour="language"
              >
                <DropdownToggle tag="a" className="nav-link">
                  <ReactCountryFlag
                    className="country-flag"
                    countryCode={
                      context.state.locale === "en"
                        ? "us"
                        : context.state.locale
                    }
                    svg
                  />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    <FormattedMessage id={langArr[context.state.locale]} />
                  </span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={(e) => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode="us"
                      svg
                    />
                    <span className="ml-1">
                      <FormattedMessage id="English" />
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={(e) => context.switchLanguage("es")}
                  >
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode="es"
                      svg
                    />
                    <span className="ml-1">
                      <FormattedMessage id="Spanish" />
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            );
          }}
        </IntlContext.Consumer>
        <NavItem className="nav-item d-none d-lg-block full-screen">
          <NavLink
            tag="span"
            id="maximize"
            className="nav-link cursor-pointer"
            onClick={() => toggleFullScreen()}
          >
            {isFullScreen ? (
              <Icon.Minimize size={21} />
            ) : (
              <Icon.Maximize size={21} />
            )}
          </NavLink>
          <UncontrolledTooltip placement="bottom" target="maximize">
            {isFullScreen ? (
              <FormattedMessage id="Normal Screen" />
            ) : (
              <FormattedMessage id="Fullscreen" />
            )}
          </UncontrolledTooltip>
        </NavItem>

        {user.role === "admin" && (
          <Dropdown
            tag="li"
            className="dropdown-notification nav-item"
            isOpen={notiDropdown}
            toggle={handleNotiDropdown}
          >
            <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Icon.Bell size={21} />
              {unseenNotifications > 0 && (
                <Badge pill color="primary" className="badge-up">
                  {" "}
                  {unseenNotifications}{" "}
                </Badge>
              )}
            </DropdownToggle>
            <DropdownMenu tag="ul" right className="dropdown-menu-media">
              <li className="dropdown-menu-header">
                <div className="dropdown-header mt-0">
                  <h3 className="text-white">
                    {unseenNotifications}{" "}
                    {unseenNotifications === 1
                      ? props.intl.formatMessage({ id: "Newfs" })
                      : props.intl.formatMessage({ id: "Newf" })}
                  </h3>
                  <span className="notification-title">
                    {unseenNotifications === 1
                      ? props.intl.formatMessage({ id: "Notification" })
                      : props.intl.formatMessage({ id: "Notifications" })}
                  </span>
                </div>
              </li>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false,
                }}
              >
                {notifications.map((notification) => {
                  let elapsedTime = "";

                  if (notification.elapsedTime.date) {
                    elapsedTime = new Date(
                      notification.elapsedTime.date
                    ).toLocaleDateString();
                  } else if (notification.elapsedTime.yesterday) {
                    elapsedTime = props.intl.formatMessage({ id: "yesterday" });
                  } else {
                    if (notification.elapsedTime.type === "now") {
                      elapsedTime = props.intl.formatMessage({
                        id: notification.elapsedTime.type,
                      });
                    } else {
                      elapsedTime = `${
                        notification.elapsedTime.value
                      } ${props.intl.formatMessage({
                        id: notification.elapsedTime.type,
                      })}`;
                    }
                  }

                  return (
                    <div
                      className="d-flex justify-content-between"
                      key={notification._id}
                      onClick={() =>
                        handleNotificationClick(
                          notification.service,
                          notification.invoice
                        )
                      }
                    >
                      <Media className="d-flex align-items-start">
                        <Media left href="#">
                          {notification.icon === "neworder" ? (
                            <Icon.PlusSquare
                              className={`font-medium-5 ${notification.priority}`}
                              size={21}
                            />
                          ) : notification.icon === "api" ? (
                            <Icon.UploadCloud
                              className={`font-medium-5 ${notification.priority}`}
                              size={21}
                            />
                          ) : notification.icon === "price" ? (
                            <Icon.DollarSign
                              className={`font-medium-5 ${notification.priority}`}
                              size={21}
                            />
                          ) : notification.priority === "danger" ? (
                            <Icon.AlertCircle
                              className={`font-medium-5 ${notification.priority}`}
                              size={21}
                            />
                          ) : notification.priority === "warning" ? (
                            <Icon.AlertTriangle
                              className={`font-medium-5 ${notification.priority}`}
                              size={21}
                            />
                          ) : (
                            <Icon.Info
                              className={`font-medium-5 ${notification.priority}`}
                              size={21}
                            />
                          )}
                        </Media>
                        <Media body>
                          <Media
                            heading
                            className={`$notification.priority} media-heading`}
                            tag="h6"
                          >
                            {notification.title[props.intl.locale]}
                          </Media>
                          <p className="notification-text">
                            {notification.text[props.intl.locale]}
                          </p>
                        </Media>
                        <small>
                          <time
                            className="media-meta"
                            dateTime="2015-06-11T18:29:20+08:00"
                          >
                            {elapsedTime}
                          </time>
                        </small>
                      </Media>
                    </div>
                  );
                })}
              </PerfectScrollbar>
              {notifications.length > 0 ? (
                <li className="dropdown-menu-footer">
                  <DropdownItem
                    tag="a"
                    className="p-1 text-center"
                    onClick={deleteAllNotifications}
                  >
                    <span className="align-middle">
                      {props.intl.formatMessage({
                        id: "Delete all notifications",
                      })}
                    </span>
                  </DropdownItem>
                </li>
              ) : (
                <li className="dropdown-menu-footer">
                  <DropdownItem tag="a" className="p-1 text-center">
                    <span className="align-middle">
                      {props.intl.formatMessage({
                        id: "There are no notifications",
                      })}
                    </span>
                  </DropdownItem>
                </li>
              )}
            </DropdownMenu>
          </Dropdown>
        )}

        {Utils.hasPermission(authRoles.loggedIn, props.user.role) ? (
          <React.Fragment>
            <Dropdown
              tag="li"
              className="dropdown-user nav-item"
              isOpen={userDropdown}
              toggle={handleUserDropdown}
              onMouseEnter={() => {
                openUserDropdown();
              }}
              onMouseLeave={() => {
                closeUserDropdown();
              }}
            >
              <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                  <span className="user-name text-bold-600">
                    {user.profile.name} {group && `(${group})`}
                  </span>
                  <NavbarUserStatus user={user} />
                </div>
                <span data-tour="user">
                  <img
                    src={
                      user.profile.avatar ? user.profile.avatar : defaultAvatar
                    }
                    className="round"
                    height="40"
                    width="40"
                    alt="avatar"
                  />
                </span>
              </DropdownToggle>
              <UserDropdown {...props} />
            </Dropdown>
          </React.Fragment>
        ) : null}
      </ul>

      {groupChangeAlert && (
        <SweetAlert
          info
          title={intl.formatMessage({ id: "Membership updated!" })}
          show={groupChangeAlert}
          onConfirm={() => {
            setGroupChangeAlert(false);
            history.go(0);
          }}
        >
          <p className="sweet-alert-text">
            <FormattedMessage id="In order to show you the new prices the page will be reloaded" />
          </p>
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(injectIntl(NavbarUser));
