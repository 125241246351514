import socket from "./socket";
import jwtService from "services/auth/jwt/jwtService";
import { store } from "../redux/storeConfig/store";
import { setAppSettings } from "redux/actions/app";

/*
 * Socket.IO Manager class
 */

class SocketManager {
  authorizing = false;
  reconnecting = false

  connectAllowed = true;
  poolAuthorizing = [];
  poolConnected = [];

  constructor() {
    socket.on("connect", () => {
      console.log('c', this.reconnecting)
      /*if (this.connectAllowed) {
        this.connectAllowed = false;

        const access_token = jwtService.getAccessTokenValidated();
        if (access_token) {
          this.authorize(access_token);
        } else {
          this.poolConnected = this.processPool(this.poolConnected);
        }

        this.loadAppSettings();
      }*/

      const access_token = jwtService.getAccessTokenValidated();
      if (access_token) {
        this.authorize(access_token);
      } else {
        this.poolConnected = this.processPool(this.poolConnected);
      }

      this.loadAppSettings();
    });

    socket.on("disconnect", (reason) => {
      console.log('d', reason)
      this.reconnecting = true
      this.connectAllowed = true
    });
  }

  processPool(pool) {
    for (const pendingEvent of pool) {
      socket.emit(pendingEvent.event, pendingEvent.data, (res) => {
        pendingEvent.resolve(res);
      });
    }

    return [];
  }

  checkConnected() {
    if (socket.disconnected) {
      this.reconnect()
      return false
    }
    return true
  }

  reconnect = () => {
    console.log('re')
    this.reconnecting = true
    this.connectAllowed = true
    //socket.connect()
  }

  authorize(accessToken) {
    if (!this.authorizing) {
      this.authorizing = true;

      this.send("authenticate", { access_token: accessToken }).then((res) => {
        if (res === "authenticated") {
          this.authorizing = false;
          this.poolConnected = this.processPool(this.poolConnected);
          this.poolAuthorizing = this.processPool(this.poolAuthorizing);
        }
      });
    }
  }

  unauthorize() {
    socket.disconnect();
  }

  //load settings and cache
  loadAppSettings() {
    const lastUpdate = window.localStorage.getItem("_asexpire");
    let update = true;

    if (lastUpdate) {
      const diffMs = new Date().getTime() - lastUpdate;
      const diffMin = Math.floor(diffMs / 1000 / 60);

      const web = window.localStorage.getItem("_as");

      if (diffMin < 60 && web) {
        update = false;
        store.dispatch(setAppSettings(JSON.parse(atob(web))));
      }
    }

    if (update) {
      this.send("get-web-settings").then((res) => {
        if (res) {
          store.dispatch(setAppSettings(res));
          window.localStorage.setItem("_asexpire", new Date().getTime());
        }
      });
    }
  }

  send(event, data) {
    return new Promise((resolve) => {
      const connected = this.checkConnected();

      if (!connected) {
        this.poolConnected.push({
          event,
          data,
          resolve,
        });
      } else if (this.authorizing && event !== "authenticate") {
        this.poolAuthorizing.push({
          event,
          data,
          resolve,
        });
      } else {
        socket.emit(event, data, (response) => {
          resolve(response);
        });
      }
    });
  }

  subscribe(event, callback) {
    this.checkConnected();
    socket.on(event, callback);
  }

  unsubscribe(event) {
    socket.off(event);
  }

  close() {
    socket.close();
  }
}

const socketManager = new SocketManager(socket);

export default socketManager;
