import socketManager from 'socket-io/SocketManager'
import themeConfig from "configs/themeConfig"

const themeColor = localStorage.getItem('theme_color')
const themeNavbar= localStorage.getItem('theme_navbar')
const themeMode = localStorage.getItem('theme_mode')
const menuLayout = localStorage.getItem('menu_layout')

const initialConfig = {
  ...themeConfig,
  menuTheme: themeColor ? themeColor : themeConfig.navbarColor,
  navbarColor : themeNavbar ? themeNavbar : themeConfig.navbarColor,
  theme : themeMode ? themeMode : themeConfig.theme,
  layout: menuLayout ? menuLayout : themeConfig.layout
}

const customizerReducer = (state = initialConfig, action) => {
  switch (action.type) {
    case "CHANGE_MODE": {
      localStorage.setItem('theme_mode', action.mode)
      socketManager.send("set-theme-mode", action.mode)
      return {...state, theme: action.mode}
    }
    case "CHANGE_MENU_LAYOUT": {
      localStorage.setItem('menu_layout', action.mode)
      socketManager.send("set-menu-layout", action.mode)
      return {...state, layout: action.mode}
    }
    case "COLLAPSE_SIDEBAR":
      return { ...state, sidebarCollapsed: action.value }
    case "CHANGE_NAVBAR_COLOR":
      return {...state, navbarColor: action.color}
    case "CHANGE_NAVBAR_TYPE":
      return { ...state, navbarType: action.style }
    case "CHANGE_FOOTER_TYPE":
      return { ...state, footerType: action.style }
    case "CHANGE_MENU_COLOR": {
      //const navbarColor = action.style !== 'primary' ? 'default' : 'primary'
      //const navbarColor = 'default'
      const navbarColor = action.style
      localStorage.setItem('theme_navbar', navbarColor)
      localStorage.setItem('theme_color', action.style)
      socketManager.send("set-theme-colors", {navbar: navbarColor, ui: action.style})
      return { ...state, menuTheme: action.style, navbarColor}
    }
    case "HIDE_SCROLL_TO_TOP":
      return { ...state, hideScrollToTop: action.value }
    case "SET_USER_THEME":{
      localStorage.setItem('theme_mode', action.value.mode)
      localStorage.setItem('theme_navbar', action.value.navbar)
      localStorage.setItem('theme_color', action.value.ui)
      localStorage.setItem('menu_layout', action.value.layout)
      return {...state, theme: action.value.mode, menuTheme: action.value.ui, navbarColor: action.value.navbar, layout: action.value.layout}
    }
    default:
      return state
  }
}

export default customizerReducer
