import React from "react"
import "assets/scss/components/app-loader.scss"
import {useSelector} from "react-redux";

const SpinnerComponent = () => {

  const web = useSelector(({app}) => app.web)


  return (
    <div className="fallback-spinner vh-100">
      {web && web.logoEmail && web.logoEmail.length > 0 &&
        <img className="fallback-logo" src={web.logoEmail} alt="logo"/>
      }
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  )

}

export default SpinnerComponent
