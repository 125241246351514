let loadingTimeout = null

export const APP_SET_LOADING = "[APP] SET_LOADING"
export const APP_SET_SETTINGS = "[APP] SET_SETTINGS"

export const setAppLoading = value => {

  if (!value){
    clearTimeout(loadingTimeout)
    return dispatch => dispatch({ type: APP_SET_LOADING, value })
  }
  else {
    return dispatch => {
      loadingTimeout = setTimeout(() => {
        dispatch({type: APP_SET_LOADING, value})
      }, 1000)
    }
  }
}

export const setAppSettings = (value) => {

  window.localStorage.setItem('_as', btoa(JSON.stringify(value)))

  if (value && value.name && document){
    document.title = value.name
  }

  return dispatch => dispatch({ type: APP_SET_SETTINGS, value })

  /*if (!value){
    clearTimeout(loadingTimeout)
    return dispatch => dispatch({ type: APP_SET_LOADING, value })
  }
  else {
    return dispatch => {
      loadingTimeout = setTimeout(() => {
        dispatch({type: APP_SET_LOADING, value})
      }, 1000)
    }
  }*/
}
